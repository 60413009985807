import { Box, Paper, Stack, Typography, TextField, Checkbox, FormControlLabel, FormGroup } from "@mui/material"
import Button from "@mui/material/Button"

import successIcon from '../../images/successIcon.svg';
import failedIcon from '../../images/failed.svg';

import { Link as RouterLink, Navigate, useAsyncError, useLocation, useNavigate } from "react-router-dom"

import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import ScrollToTopOnMount from "../scrolltoview";
import { useEffect, useState } from "react";
import axios from "axios";
import { CircularProgress } from "@mui/material";

import { BASE_URL_VOIPSWITCH, ENDPOINTS } from "../..";

import { Link } from "react-router-dom";

export default function ConfirmBuyPlan(props) {
    const [planDescription, setPlanDescription] = useState('');
    
    const [checked, setChecked] = useState([true, false]);
    const [product, setProduct] = useState('')
    const [amount, setAmount] = useState(5)
    const navigate = useNavigate()

    const handleChange = (event) => {
        setChecked(event.target.checked);
    }

    if (planDescription !== "") {
        document.getElementById("progress").style.visibility = "hidden"
    }

    useEffect(() => {
        if (props.plan == 6 || props.plan == 7 || props.plan == 8) setAmount(10)
        else setAmount(5)

        // 10 dollars plans                    
        if (props.plan == 6) setProduct('prod_accordCallingNigeria10Dollars')
        else if (props.plan == 7) setProduct('prod_accordCallingGhana10Dollars')
        else if (props.plan == 8) setProduct('prod_accordCallingTanzania10Dollars')

        // 5 dollars plans        
        else if (props.plan == 9) setProduct('prod_accordCallingNigeria5Dollars')
        else if (props.plan == 10) setProduct('prod_accordCallingGhana5Dollars')
        else if (props.plan == 11) setProduct('prod_accordCallingTanzania5Dollars')
        
        axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['getPlanData']}`, {
          id: props.plan
        })
        .then((res) => {
        //   console.log(res['data'])
        // alert('got plan details')
        // console.log(res)
          setPlanDescription(res['data']['plan']['name'])
        })
        .catch((err) => {
            console.log("an error occured")
            navigate("/Dashboard", {state: {page: "overview"}})
            alert(err.message)
        })
      })

    return (
        <Paper component="div"
               sx={{ display: 'flex', flexDirection: 'column',
               pt: 3, pb: 10, mt: 3, mx: 'auto', width: {xs: '90vw', sm: '600px'},
               borderRadius:4 }}>
            <ScrollToTopOnMount />
            <Box display='flex' px={3} alignItems='center'>
                <RouterLink to='/Dashboard' state={{page: 'overview' }} style={{ textDecoration: 'none', marginTop: '-24px' }}>
                    <KeyboardBackspace sx={{ position: 'absolute' }}/>
                </RouterLink>
                <Typography variant="h5" fontWeight={700} sx={{ mx: 'auto'}}>Calling Plans</Typography>
                <CircularProgress id="progress" color="success" sx={{ position: 'relative', bottom: '10px', height: "10px", width: "10px"}} />
            </Box>
            <Box bgcolor="#E2E2E2" width='100%' height='2px'></Box>

            <Box px={3}>
                <Stack direction='column' pt={6} textAlign="center">
                    {/* <Typography variant="body1" fontWeight={700}>{props.country}</Typography>     */}
                    <Typography variant="h5" fontWeight={700}>You are purchasing the calling plan: {planDescription}</Typography>

                    <FormGroup  sx={{display: 'flex', flexDirection: "row", justifyContent: "center", mt:3 }}>
                        <FormControlLabel control={<Checkbox checked={checked} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }}  size="small" sx={{color: "#8DC641", '&.Mui-checked': {color: "#8DC641",},}}/>} label="Automatically bill monthly" />
                    </FormGroup>
                    
                    {(() => {
                        if (checked) {
                            return (<Link to="/Dashboard" state={{page: "makePayment2", product: product}}>
                                        <Button color='success' variant="contained"
                                        sx={{  mt: 5, py: 1.5, backgroundColor: '#8DC641', textTransform: 'none', width: '100%' }}>
                                            Confirm
                                        </Button>
                                    </Link>)
                        } else {
                            return (
                                    <Link to="/Dashboard" state={{page: "makePayment3", plan: props.plan, amount: amount}}>
                                        <Button color='success' variant="contained"
                                            sx={{  mt: 5, py: 1.5, backgroundColor: '#8DC641', textTransform: 'none', width: '100%' }}>
                                                Confirm
                                        </Button>
                                    </Link>
                            )
                        }
                    })()}
            
                    <RouterLink to='/Dashboard' state={{page: 'overview' }}  style={{ textDecoration: 'none' }}>
                        <Button  color='success' variant="outlined"
                        sx={{  mt: 3, py: 1.5, color: '#8DC641', borderColor: '#8DC641', textTransform: 'none', width: '100%' }}>
                            Cancel
                        </Button>
                    </RouterLink>
                </Stack>
            </Box>
        </Paper>
    )
}

export function BuyPlanSuccessful(props) {
    return (
            <Paper component="div"
            sx={{ display: 'flex', flexDirection: 'column',
            pt: 3, pb: 10, mt: 3, mx: 'auto', width: {xs: '90vw', sm: '600px'},
            borderRadius:4 }}>

                <Box display='flex' px={3} alignItems='center'>
                    <RouterLink to='/Dashboard' state={{page: 'overview' }} style={{ textDecoration: 'none', marginTop: '-24px' }}>
                        <KeyboardBackspace sx={{ position: 'absolute', color: 'black' }}/>
                    </RouterLink>
                    <Typography variant="h5" fontWeight={700} sx={{ mx: 'auto'}}>Calling Plans</Typography>
                </Box>
                <Box bgcolor="#E2E2E2" width='100%' height='2px'></Box>

                <Box px={3}>
                    <Stack direction='column' pt={6} textAlign="center">
                        <Box component='img'
                                width={120}
                                mx='auto'
                                src={successIcon} />
                        <Typography variant="h5" fontWeight={700}>Success!</Typography>    
                        <Typography variant="body1" fontWeight={700}>Plan has been added to your account</Typography>
                        {(() => { 
                            if (props.number) {
                                return (
                                        <Typography variant="body1" mt={2}>
                                            Your number is: <Typography variant="h4">{props.number}</Typography>
                                        </Typography>)
                            }})()}
                        
                        <RouterLink to='/Dashboard' state={{page: 'myaccount' }}  style={{ textDecoration: 'none' }}>
                            <Button  color='success' variant="contained"
                            sx={{  mt: 7.5, py: 1.5, backgroundColor: '#8DC641', textTransform: 'none', width: '100%' }}>
                                Okay, Thank you
                            </Button>
                        </RouterLink>
                    </Stack>
                </Box>
            </Paper>
    )
}

export function BuyPlanFailed(props) {
    return (
            <Paper component="div"
            sx={{ display: 'flex', flexDirection: 'column',
            pt: 3, pb: 10, mt: 3, mx: 'auto', width: {xs: '90vw', sm: '600px'},
            borderRadius:4 }}>

        <Box display='flex' px={3} alignItems='center'>
            <RouterLink to='/Dashboard' state={{page: 'overview' }} style={{ textDecoration: 'none', marginTop: '-24px' }}>
                <KeyboardBackspace sx={{ position: 'absolute', color: 'black' }}/>
            </RouterLink>
            <Typography variant="h5" fontWeight={700} sx={{ mx: 'auto'}}>Calling Plans</Typography>
        </Box>
        <Box bgcolor="#E2E2E2" width='100%' height='2px'></Box>

        <Box px={3}>
            <Stack direction='column' pt={6} textAlign="center">
                <Box component='img'
                        width={120}
                        mx='auto'
                        src={failedIcon} />
                <Typography variant="h5" pt={3} fontWeight={700}>Request not successful</Typography>    
                <Typography variant="body1" >{props.message}</Typography>
                <RouterLink to='/Dashboard' state={{page: 'addfunds' }}  style={{ textDecoration: 'none' }}>
                    <Button  color='success' variant="contained"
                    sx={{  mt: 7.5, py: 1.5, backgroundColor: '#8DC641', textTransform: 'none', width: '100%' }}>
                        Add funds
                    </Button>
                </RouterLink>
            </Stack>
        </Box>
    </Paper>
    )
}
