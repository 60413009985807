import { Box, Paper, Typography, Tabs, Tab, Stack, Button, Container, Modal } from "@mui/material";
import { Link } from "react-router-dom";

import { Link as RouterLink } from "react-router-dom";

import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';

import PropTypes from 'prop-types';
import { useEffect, useState } from "react";

import ic_recur from "../../images/ic_recur.svg";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import { ENDPOINTS, BASE_URL_VOIPSWITCH, BACKEND } from "../..";
import axios from "axios";
import optionIcon from '../../images/options.svg';
import { CircularProgress } from "@mui/material";
import ScrollToTopOnMount from "../scrolltoview";

  const columns2 = [
    { id: 'date', label: 'STARTED', minWidth: 100 },
    { id: 'country', label: 'COUNTRY', minWidth: 100 },
    { id: 'plans', label: 'PLAN', minWidth: 100, align: 'left',},
    { id: 'duration', label: 'DURATION', minWidth: 100, align: 'left',},
    { id: 'subId', label: '', minWidth: 50, align: 'left',},
  ];

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 300,
    bgcolor: 'white',
    borderRadius: '20px',
    border: '2px solid #fff',
    boxShadow: 24,
    p: 4,
    textAlign: 'center',
  };
  
  function createData(date, country, plans, duration, subId) {
    return { date, country, plans, duration, subId};
  }

    
export default function RecurringPlans() {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [rows, setRows] = useState([]);

    const [options, setOptions] = useState(false);
    const [openModal, setOpenModal] = useState(true);
    const handleClose = () => setOpenModal(false);

    const [sub, setSub] = useState('')

    const fetchData = async () => {
        try {
            const response1 = await axios.get(`${BACKEND}${ENDPOINTS['createStripeSubscription']}?userId=${sessionStorage.getItem('idClient')}`)
            const responseSubs = response1['data']['subs']

            const newRows = []    
            for (let val of responseSubs)  {
                const response2 = await axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['getPlanDetails']}`, {
                                            "id": val['planId']
                                        })
                let country = response2['data']['plan']['name'].split('-')[0]
                let name = response2['data']['plan']['name']
                newRows.push(createData(val['date'], country, name, '30 days', val['subId']))
            }
            
            
            const response3 = await axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['getClientPlanList']}`, {
                clientId: sessionStorage.getItem('idClient'),
                clientType: 32,
            })

            const responsePlans = response3['data']['plans']
            for (let val of responsePlans)  {
                newRows.push(createData(' ', val['name'].split('-')[0], val['name'], '30 days', ''))
            }// period = res['data']['plan']['period']
                // period + " month(s)"
                // console.log(newRows)
                // console.log()

            setRows(newRows)
            document.getElementById("progress").style.visibility = "hidden"
        } catch(error) {
            // alert(error)    
            console.error(error);
            document.getElementById("progress").style.visibility = "hidden"
        }
    }

    useEffect(() => {
        fetchData()
    }, [])
    
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const optOut = async () => {
        try {
            document.getElementById("progress").style.visibility = "visible"    
            const res = await await axios.post(`${BACKEND}${ENDPOINTS['cancelSubscription']}`, {
                "client": "AccordCalling",
                "subId": sub
            })
            if (res['data']['status'] == 'success') {
                fetchData()    
                handleClose()
                console.log(res)
                document.getElementById("progress").style.visibility = "hidden"
            }
            console.log(res)
        } catch(err) {
            document.getElementById("progress").style.visibility = "hidden"    
            alert('an error occurred')
            console.log(err)
        }
    }

    return (
        <Paper component="div"
        sx={{ display: 'flex', flexDirection: 'column',
        pt: 3, pb: 10, mt: 3, mx: 'auto', width: {xs: '90vw', sm: '1000px'},
        borderRadius:4 }}>
            <ScrollToTopOnMount />
            <Box display='flex' px={3} pb={2} alignItems='center'>
                <RouterLink to='/Dashboard' state={{page: 'myaccount' }} style={{ textDecoration: 'none', marginTop: '-24px' }}>
                    <KeyboardBackspace sx={{ position: 'absolute', color: 'black' }}/>
                </RouterLink>
                <Typography variant="h5" fontWeight={700} sx={{ mx: 'auto'}}>Manage Plans</Typography>
                <CircularProgress id="progress" color="success" sx={{ position: 'relative', bottom: '10px', height: "5px", width: "10px"}} />
            </Box>
            <Box bgcolor="#E2E2E2" width='100%' height='2px'></Box>

            <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                <TableContainer sx={{ maxHeight: 440 }}>
                    <Table stickyHeader aria-label="sticky table">
                    <TableHead>
                        <TableRow>
                        {columns2.map((column2) => (
                            <TableCell
                            key={column2.id}
                            align={column2.align}
                            style={{ minWidth: column2.minWidth }}
                            >
                            {column2.label}
                            </TableCell>
                        ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row) => {
                            return (
                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                {columns2.map((column2) => {
                                const value = row[column2.id];
                                
                                if (value == row['date'] && row['subId'] != "") {
                                    return (
                                        <TableCell key={column2.id} align={column2.align}>
                                        <Box component='img' pr={1} src={ic_recur} />
                                        {column2.format && typeof value === 'number'
                                            ? column2.format(value)
                                            : value}
                                        </TableCell>
                                    );
                                }
                                if (value !== row['subId'])
                                return (
                                    <TableCell key={column2.id} align={column2.align}>
                                    {column2.format && typeof value === 'number'
                                        ? column2.format(value)
                                        : value}
                                    </TableCell>
                                );
                                })}
                                {(() => {
                                    if (row['subId'] !== "") {
                                        return (
                                            <TableCell key={optionIcon} onClick={() => {    
                                                setSub(row['subId'])
                                                setOptions(true)
                                                setOpenModal(true)}} sx={{cursor: 'pointer', px: 5}}>
                                            <Typography color="red" variant="body2">Cancel Plan</Typography>
                                        </TableCell>
                                        )
                                    }
                                })()}
                                
                            </TableRow>
                            );
                        })}
                    </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    rowsPerPageOptions={[10, 25, 100]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
                </Paper>    

            {(() => {
            if (options) 
                return (
                    <Box>
                        {/* <Button onClick={handleOpen}>Open modal</Button> */}
                        <Modal
                        open={openModal}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        >
                        <Box sx={style} >
                            <div>
                                <Typography>
                                    Your subscription for this plan will not renew after the current term expires.   
                                </Typography>    
                            
                                <br/>
                                <Stack direction="row" spacing={10} justifyContent="center">
                                    <div onClick={optOut}>
                                        <Typography  color="red" sx={{cursor: 'pointer',}}  >
                                                Opt-Out
                                        </Typography>
                                    </div>
                                    <div onClick={() => handleClose()}>
                                        <Typography onClick={() => {}}  sx={{cursor: 'pointer',}}  >
                                                Cancel
                                        </Typography>
                                    </div>
                                </Stack>
                            </div>    
                        </Box>
                        </Modal>
                    </Box>
                )})()}
        </Paper>
    )
}