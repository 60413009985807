import { CardCvcElement, CardExpiryElement, CardNumberElement } from "@stripe/react-stripe-js";
import { Box, Button, Paper, Stack, FormControl, Radio, RadioGroup, FormControlLabel, Typography, CircularProgress } from '@mui/material';
import cardNumberIcon from '../../images/cardNumberIcon.svg';
import stripePaymentIcon from '../../images/stripe-payment-icon.png';

import { useElements, useStripe } from "@stripe/react-stripe-js";
import { useState, useEffect } from "react";
import axios from "axios";
import { BACKEND, ENDPOINTS, BASE_URL_VOIPSWITCH, BASE_URL_VOIPSWITCH2, EMAILAPP } from "../..";

import { Navigate } from "react-router-dom";
import { Link as RouterLink } from "react-router-dom";
import KeyboardBackspace from '@mui/icons-material/KeyboardBackspace';
import sha1 from 'js-sha1';


// CheckoutForm without subscription implemented, with options of wallet or card
export function CheckoutForm3(props) {
  const amount = props.amount
  const plan = props.plan
  
  const [paymentSuccess, setPaymentSuccess] = useState(false)
  const [paymentFailed, setPaymentFailed] = useState(false)

  const [disableSubmit, setDisableSubmit] = useState(false)
  const [madeRequest, setMadeRequest] = useState(false);
  const [planStatus, setPlanStatus] = useState(false);
  const [planStatusMessage, setPlanStatusMessage] = useState('');
  const [number2, setNumber] = useState('')
  const [clientSecret, setClientSecret] = useState('')

  // console.log(amount, plan)  

  useEffect(() => {
    axios.post(`${BACKEND}${ENDPOINTS['getStripeSecret']}`, {
        client: "AccordCalling",
        amount: Number(props.amount) * 100,
        currency: "usd"
    })
    .then((res) => {
      // console.log("Success")

      setClientSecret(res['data']['clientSecret'])
    })
    .catch((err) => {
      console.log(err)
    })
    }, [props.amount])

  const stripe = useStripe();
  const elements = useElements();
        
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.

    event.preventDefault();
  
    setDisableSubmit(true)
    document.getElementById("circularProgress").style.visibility = "visible"

    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    // console.log('client secret is' + clientSecret)
    
    // CardCvcElement, CardExpiryElement, CardNumberElement
    const result1 = await stripe.createPaymentMethod({
      type: 'card',
      card: elements.getElement(CardNumberElement),
    })
    if (result1.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log(result1.error);
      
      document.getElementById("circularProgress").style.visibility = "hidden"
      
      setDisableSubmit(false)
      setPaymentFailed(true)
    } else {
      // console.log('payment method is ', result1.paymentMethod)

      const result2 = await stripe.confirmCardPayment(clientSecret, {
          payment_method: result1.paymentMethod.id,
          // receipt_email: "jtobi8161@gmail.com"
        })  

      if (result2.error) {
      // Show error to your customer (for example, payment details incomplete)
      console.log('in result2 ', result2.error);

      document.getElementById("circularProgress").style.visibility = "hidden"
      setDisableSubmit(false)
      setPaymentFailed(true)
      } else {
        // console.log('result2 returned successfully ', result2.paymentIntent)
        document.getElementById("circularProgress").style.visibility = "hidden"
        setDisableSubmit(false)
        
        axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['addFunds']}`, {
          "money":props.amount,
          "paymentType":"PrePaid", 
          "idClient":sessionStorage.getItem('idClient'),
          "clientType":32,
          "addToInvoice":false,
          "description":"Web top up"
        })
        .then((res) => {
          addPlan()
        })
        .catch((err) => {
          console.log(err)
          document.getElementById("circularProgress").style.visibility = "hidden"
        })
      } 
    }
    
  };

  const addPlan = (e) => {
    if (e) e.preventDefault()

    axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['addPlan']}`, {
        clientId: sessionStorage.getItem('idClient'),
        clientType: 32,
        planId: props.plan
      })
      .then((res) => {
        // console.log(res['data'])
        
        if (res['data']['status'] === -1) {
            setPlanStatus(false)    
            setMadeRequest(true)
            let message = res['data']['responseStatus']['message']
            setPlanStatusMessage(message)
        } else {
          axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['getClientInfo']}`, {
            "idClient": sessionStorage.getItem('idClient'),
            "clientType": 32
          }).then((res) => {
            // Set correct values of amount, country and number of minutes
            let amount = ""; let countryName = ""; let minutes = "";
            if (props.plan == 6 || props.plan == 7 || props.plan == 8) amount="$10"
            else amount = "$5"
            if (props.plan == 6 || props.plan == 7) minutes = "250mins"
            else if (props.plan == 8) minutes = "150mins"
            else if (props.plan == 9 || props.plan == 10) minutes = "60mins"
            else minutes = "30mins"
            if (props.plan == 6 || props.plan == 9) countryName = "Nigeria"
            else if (props.plan == 7 || props.plan == 10) countryName = "Ghana"
            else if (props.plan == 8 || props.plan == 11) countryName = "Tanzania"

            console.log(res['data']['personal']['eMail'])
            axios.post(`${EMAILAPP}${ENDPOINTS['accordMail']}`, {
              "to_email": res['data']['personal']['eMail'],
              "email_template": "template1",
              "to_name": sessionStorage.getItem('firstName') || sessionStorage.getItem('lastName') || " ",
              "country_name": countryName,
              "plan_title": "AccordCalling " + countryName + " plan for " + minutes,
              "amount": amount,
              "minutes": minutes
            }, {headers: {'Authorization': '919c02f004339822ecb967cc526977b528f7ba55'}}).catch(err => console.log(err))
            addNumber()
          }).catch((err) => console.log(err))

        }
      })
      .catch((err) => {
        console.log(err)
    })
  }

  const addNumber = () => {
  if (props.plan == 6 || props.plan == 7 || props.plan == 8) {
      let techPrefix = "CP:!09767645;DP:+234->0 OR 234->0 OR 00234->0 OR 233->88233 OR 00233->88233 OR 255->88255 OR 00255->88255;TP:+->"
      if (props.plan == 6) {
          
          axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['getNumberList']}`, {
              "areaId":0,
              "countryId":17,
              "number":"*",
              "pageOffset":0,
              "pageSize":0
            })
            .then((res) => {
              let  i = -1;
              let number = ''
              do {
                  i++    
                  number = res['data']['data'][i]['number']
              } while(res['data']['data'][i]['status'] !== "Available" 
                      && i < (res['data']['data']).length - 1);
              
              if (res['data']['data'].length - 1 === i)  {
                  if (res['data']['data'][i]['status'] === "Available" ) {
                      alert('no numbers available')
                  }
              } else {
                  setNumber(number)
                  techPrefix=`CP:!0${number};DP:+234->0 OR 234->0 OR 00234->0 OR 233->88233 OR 00233->88233 OR 255->88255 OR 00255->88255;TP:+->`
                  
                  // added here because of asynchronity
                  // alert('techPrefix to add ' + techPrefix)
                  axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['setDid']}`, {
                      clientId: sessionStorage.getItem('idClient'),
                      techPrefix: techPrefix,
                    })
                  .then((res) => {
                      // console.log(res)
                      if (res['data']['status'] === -1) {
                          alert('number not linked')
                          setPlanStatus(true)
                          setMadeRequest(true)
                      }
                      else {
                          setPlanStatus(true)
                          setMadeRequest(true)    
                          alert('outgoing number linked successfully')
                      }  
                  })
                  .catch((err) => {
                      console.log(err)
                      alert('error while linking number')
                      setPlanStatus(true)
                      setMadeRequest(true)
                  })    
                  
                  
                  let authorization = ""

                  if (sessionStorage.getItem("password"))
                      authorization = "Basic " + btoa(sessionStorage.getItem('login') + ":" + sha1(sessionStorage.getItem("password")))
                  else alert('password missing, please re-login to sync data.')
                  
                  // console.log(authorization)

                  axios.post(`${BASE_URL_VOIPSWITCH2}${ENDPOINTS['buyNumber']}`, {
                      "countryId":17, "purches": [
                          {"quantity":1,"phoneNumber":number, "areaCode":"01", "countryPhoneCode":"234", "countryCode":"234", "areaName":"", "voxboneGroupId":0,"localAreaId":0,"cnam":"", "channels":0,"dIDWWUniqueCode":"", "nPA":"", "nXX":"", "city":"", "stateCode":"", "phoneGroup":"", "resellerId":84,"resellerLevel":0}],"resellerDb":"", "promotion":false,"resellerRetailClient":"", "subscription":false
                    }, { headers: { 
                      "Authorization": authorization}
                    })
                    .then((res) => {
                      // console.log(res['data']['data'][0]['status'])
                      console.log(res)
                      if (res['data']['data'][0]['status']) {
                          
                          alert('incoming number attached.')
                          // console.log(res['data']['data'][0]['did'])
                          // console.log('resp from buyNumber ', res)
                      }
                      else {  
                        alert('incoming number error ' + res['data']['data'][0]['message'])
                      } 
                    })
                    .catch((err) => {
                      alert('incoming call number error')
                      console.log(err)
                      
                    })
              }

            })    
            .catch((err) => {
              alert('could not map number for incoming')
              console.log(err)
            })
      } 
      
      if (props.plan == 7 || props.plan == 8) {
        // alert('techPrefix to add ' + techPrefix)
        axios.post(`${BASE_URL_VOIPSWITCH}${ENDPOINTS['setDid']}`, {
            clientId: sessionStorage.getItem('idClient'),
            techPrefix: techPrefix,
          })
        .then((res) => {
            // console.log(res)
            if (res['data']['status'] === -1) {
                alert('number not linked')
                setPlanStatus(true)
                setMadeRequest(true)
            }
            else {
                setPlanStatus(true)
                setMadeRequest(true)    
                alert('outgoing number linked successfully')
            }
            
        })
        .catch((err) => {
            console.log(err)
            alert('error while linking number')
            setPlanStatus(true)
            setMadeRequest(true)
        })
      }
  }

  else {
      setPlanStatus(true)
      setMadeRequest(true)
  }
  }

  
  const [selectedValue, setSelectedValue] = useState('a');
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const controlProps = (item) => ({
    checked: selectedValue === item,
    onChange: handleChange,
    value: item,
    name: 'size-radio-button-demo',
    inputProps: { 'aria-label': item },
  });

  if (paymentSuccess)
    return <Navigate to="/Dashboard" state={{ page: "addFundsSuccessful"}} />
  if (paymentFailed)
    return <Navigate to="/Dashboard" state={{ page: "addFundsFailed"}} />

  if (madeRequest && planStatus)
      return <Navigate to='/Dashboard' state={{page: 'buyPlanSuccessful', number: number2}} />
  else if (madeRequest && !planStatus)
      return <Navigate to='/Dashboard' 
              state={{page: 'buyPlanFailed',
              message: planStatusMessage}} />
  else
  return (
      <Box display='flex' justifyContent='center'>
      <Paper sx={{pt: 3, pb: 10, mt: 3, px: {xs: 2, md: 5}, width: {xs: '80vw', md: '50vw', lg: '600px'}}} position="relative">
        <CircularProgress id="circularProgress" sx={{ visibility:"hidden", position: "absolute", left: "45%", top: "45%" }} color="success" />
        <Box display='flex' px={3} alignItems='center'>
            <RouterLink to='/Dashboard' state={{page: 'overview' }} style={{ textDecoration: 'none', marginTop: '-24px' }}>
                <KeyboardBackspace sx={{ position: 'absolute', color: 'black' }}/>
            </RouterLink>
            <Typography variant="h5" fontWeight={700} sx={{ mx: 'auto'}}>Add Funds</Typography>
        </Box>
        <RadioGroup sx={{mt:3}} row aria-labelledby="payment option" name="row-radio-buttons-group">
          <FormControlLabel value="debit" control={<Radio {...controlProps('a')} size='small' color="success" />} label="Fund with debit/credit card" />
          <FormControlLabel value="wallet" control={<Radio {...controlProps('b')} size='small' color="success" />} label="Fund with AccordCalling Wallet" />
        </RadioGroup>
        {(() => {
          if (selectedValue == 'a') {
            return (
                <Stack component='form' onSubmit={(e) => handleSubmit(e)} direction='column' pt={3}> 
                    
                    <Typography variant="p" mt={5} mb={2} >Card Number</Typography>
                    <Box position='relative' border={1} borderRadius={1} borderColor='#CBCBCB' px={3} py={2}>
                      <CardNumberElement />
                      <Box component='img'
                          position='absolute'
                          right="15px"
                          top="15px"
                          src={cardNumberIcon} />
                      
                    </Box>
                    <Stack display='flex' direction='row' mt={4} spacing={3}>
                      <Box flexGrow={1}>
                        <Typography variant='p'>Expiration Date</Typography>                    
                      
                        <Box border={1} mt={2} borderRadius={1} borderColor='#CBCBCB' px={3} py={2}>
                          <CardExpiryElement />
                        </Box>
                      </Box>  
                      <Box flexGrow={1}>
                        <Typography  variant='p'>CVV</Typography>
                        <Box border={1} mt={2} borderRadius={1} borderColor='#CBCBCB' px={3} py={2}>
                          <CardCvcElement />
                        </Box>
                      </Box>
                    </Stack>
                      <Button disabled={disableSubmit} type='submit' color='success' variant="contained"
                        sx={{  mt: 7.5, py: 1.5, backgroundColor: '#8DC641', textTransform: 'none', width: '100%' }}>
                            Make Payment
                        </Button>                  
                <Box component='img' 
                      mt={10}
                      sx={{width: {xs: '50vw', md: '40vw', lg: '250px'}}} 
                      src={stripePaymentIcon} 
                      mx='auto'
                    />
            </Stack>
            )
          }
          else return (
            <Button onClick={addPlan} disabled={disableSubmit} type='submit' color='success' variant="contained"
              sx={{  mt: 7.5, py: 1.5, backgroundColor: '#8DC641', textTransform: 'none', width: '100%' }}>
                  Confirm
              </Button>                  
          )
        })()}
        
        </Paper>  
      </Box>
  )
}